<template>
  <div>
    <div class="container-main">
      <div class="title-vdo-01">যোগাযোগ</div>
      <div style="text-align: center;" v-if="body">{{ body }}</div>
      <div class="contact-us">
        <form method="post" @submit="feedback">
          <div class="form-control-2">
            <div class="form-group-2">
              <label> আপনার নাম </label>
              <input
                type="text"
                placeholder="আপনার নাম টাইপ করুন"
                v-model="name"
                required
              />
            </div>
            <div class="form-group-2">
              <label> আপনার ফোন নম্বর </label>
              <input
                type="number"
                placeholder="আপনার ফোন নম্বর টাইপ করুন"
                v-model="msisdn"
                minlength="11"
                maxlength="14"
                required
              />
            </div>
          </div>
          <div class="form-group-2">
            <label> মেসেজ </label>
            <textarea
              name="message"
              id="your-mesg"
              rows="6"
              v-model="message"
              placeholder="আপনার মেসেজ লিখুন"
              required
            ></textarea>
          </div>
          <div class="form-group-2">
            <button type="submit" @click="feedback" v-if="button">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Contact",
  data: () => {
    return {
      name: null,
      msisdn: null,
      message: null,
      msg: false,
      body: null,
      button: true
    };
  },
  methods: {
    async feedback(e) {
      try {
        e.preventDefault();
        this.button = false;
        let name = this.name;
        let msisdn = this.msisdn;
        let message = this.message;
        if (name && msisdn && message) {
          const host = this.$root.url();
          const path = "api/v1/hullor/subscriber/feedback";
          const url = `${host}/${path}`;
          let data = {
            app_type: "wap",
            name: this.name,
            msisdn: this.msisdn,
            message: this.message
          };
          let response = await axios.post(url, data, {
            headers: {
              token: "f3827eeb536655b578de9aaeb53acf73"
            }
          });
          if (response.data.status === "SUCCESS") {
            this.body = "Successfully saved data";
            this.name = this.phone = this.message = null;
            this.button = true;
            return true;
          } else {
            this.body = response.data.message;
            this.button = true;
            return true;
          }
        } else {
          this.button = true;
          this.body = "Please fill up all fields";
          return true;
        }
      } catch (error) {
        console.log(error);
        this.body = "Something wrong, Please try again later";
        this.button = true;
        return true;
      }
    }
  }
};
</script>

<style scoped></style>
